*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

html {
	font-size: 62.5%;
}

body {
	background-color: #071521;
	color: #ffffff;
	font-family: 'Roboto', sans-serif;
	box-sizing: border-box;
	padding: 0 15rem;
}

.header {
	margin: 3rem 0;
	text-align: center;
}

.header h1 {
	font-size: 3rem;
	margin-bottom: 1rem;
	color: #e31040;
	letter-spacing: 1px;
}

.header h2 {
	font-size: 1.4rem;
	font-weight: 300;
	margin-bottom: 1.4rem;
}

.games {
	margin-bottom: 5rem;
}

.title {
	display: inline-block;
	border-bottom: 2px solid #e31040;
	font-size: 2.4rem;
	margin-bottom: 1.4rem;
	font-weight: normal;
}

.count {
	text-align: left;
	font-size: 1.2rem;
	margin-bottom: 2rem;
}

.list {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 2rem;
	margin-bottom: 3rem;
}

.card {
	display: flex;
	justify-content: space-between;
	font-size: 1.6rem;
	padding: 1.2em 1em;
	background-color: #0e2231;
	border-radius: 3px;
	box-shadow: 0 0 0.5rem rgba(23, 47, 66, 0.3);
	transition: all 0.3s ease-out;
}

.card:hover {
	box-shadow: 0 0 1rem rgba(23, 47, 66, 0.5);
	transform: translateY(-5px);
}

@media screen and (max-width: 1250px) {
	body {
		padding: 0 8rem;
	}
}

@media screen and (max-width: 1110px) {
	body {
		padding: 0 5rem;
	}
}

@media screen and (max-width: 1050px) {
	.header h1 {
		font-size: 2.4rem;
	}

	.header h2 {
		font-size: 1.4rem;
	}

	.header p {
		font-size: 1rem;
	}

	.list {
		grid-template-columns: 1fr 1fr;
		grid-gap: 2rem 7rem;
	}

	.card {
		font-size: 1.4rem;
	}
}

@media screen and (max-width: 870px) {
	body {
		padding: 0 7rem;
	}

	.list {
		grid-template-columns: 1fr;
		grid-gap: 2rem 0;
	}
}

@media screen and (max-width: 600px) {
	body {
		padding: 0 5rem;
	}
}

@media screen and (max-width: 460px) {
	body {
		padding: 0 2rem;
	}
}
